// ==========[ BUTTONS ]==========

.btn {
  padding: 7px 16px;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  font-weight: 300;
}

.btn-primary {
  @include quart-transition(400ms);
  display: flex;
  color: $white;
  background-color: $red;
  align-items: center;
  justify-content: center;

  img {
    @include quart-transition(400ms);
    width: 10px;
    margin-left: 7px;
  }

  &:hover {
    color: $white;
    background-color: darken($red, 6%);

    img {
      margin-left: 14px;
    }
  }
}

.btn-secondary {
  @include quart-transition(400ms);
  display: flex;
  color: $white;
  background-color: $black;
  align-items: center;
  justify-content: center;

  img {
    @include quart-transition(400ms);
    width: 10px;
    margin-left: 7px;
  }

  &:hover {
    color: $white;
    background-color: darken($red, 6%);

    img {
      margin-left: 14px;
    }
  }
}

.btn-link {
  color: $black;
  font-size: 15px;
  font-weight: bold;
  line-height: 26px;

  &:hover {
    color: $red;
    text-decoration: none;
  }
}

.btn-specialisatie {
  display: flex;
  margin-bottom: 35px;
  align-items: center;
  justify-content: flex-start;

  .icon {
    width: 41px;
    height: 40px;
    margin-right: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  p {
    @include quart-transition(400ms);
  }

  img {
    @include quart-transition(400ms);
    margin-left: 18px;
  }

  &:hover {
    text-decoration: none;

    p {
      color: $red;
    }

    img {
      margin-left: 25px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.btn-video {
  @include quart-transition(400ms);
  color: $black;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;

  img {
    @include quart-transition(400ms);
    margin-right: 15px;
  }

  &:hover {
    color: $red;
    text-decoration: none;

    img {
      margin-right: 8px;
    }
  }
}

// ==========[ BLOCKS ]==========

.socials {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  a {
    display: flex;
    width: 45px;
    height: 45px;
    color: $white;
    background-color: $black;
    align-items: center;
    font-size: 21px;
    justify-content: center;

    &:hover {
      color: $black;
      background-color: $white;
      text-decoration: none;
    }
  }
}

.header-block {
  display: flex;
  position: relative;
  width: 100%;
  height: 575px;
  padding: 150px 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: flex-end;
  justify-content: flex-end;

  .content {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;

    h2 {
      display: inline-block;
      padding: 4px 10px 8px 20px;
      color: $black;
      background-color: $white;
      text-transform: uppercase;
      font-family: $ff-acumin;
      font-size: 40px;
      font-weight: 300;
      line-height: 48px;
      letter-spacing: 0.34em;
    }

    .cms {
      max-width: 50%;
      color: $white;
      text-align: right;
      font-family: $ff-proxima-nova;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;

      .highlight {
        display: inline;
        padding: .5rem;
        padding-right: 0;
        padding-left: 0;
        color: white;
        background: $black;
        box-shadow: .5rem 0 0 $black,
          -.5rem 0 0 $black;
      }
    }
  }
}

.project-block {
  @include quart-transition(400ms);
  display: block;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .overlay {
    @include quart-transition(400ms);
    display: flex;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: transparentize($grey-dark, .4);
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      @include quart-transition(400ms);
      color: $white;
    }

    .central-icon {
      @include quart-transition(400ms);
      display: flex;
      width: 120px;
      height: 120px;
      opacity: 0;
      border-radius: 999px;
      background-color: $black;
      align-items: center;
      align-self: center;
      justify-content: center;
      transform: scale(0);
    }
  }

  &:hover {
    text-decoration: none;

    .overlay {
      background-color: transparentize($grey-dark, 1);

      .cms,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        opacity: 0;
      }

      .central-icon {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &.blend-supported {
    background-color: transparentize($grey-dark, 0);
    background-blend-mode: multiply;

    .overlay {
      background-color: transparent;
    }

    &:hover {
      background-color: transparentize($grey-dark, 1);
    }
  }
}

.overlap-boxes {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .left {
    z-index: 999;
    position: relative;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 50px;
    background-color: $red;
    flex: 0 0 38%;

    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }

    .btn {
      position: absolute;
      bottom: -17px;
    }
  }

  .right {
    width: 100%;
    margin-left: -10%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex: 0 0 71%;
  }
}

.black-block {
  padding: 40px;
  background-color: $black;

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }
}

.video-block {
  display: none;
  position: relative;
  width: 100%;
  padding-top: 66%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .button {
    @include quart-transition(400ms);
    display: flex;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    color: $white;
    border-radius: 9999px;
    background-color: $red;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .button {
      color: $red;
      background-color: $white;
      transform: scale(1.1);
    }
  }

  &.active {
    display: block;
  }
}

// ==========[ FORMS ]==========

form {
  .cms {
    color: $black;
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.4px;
  }

  .form-group {
    position: relative;

    .form-control {
      padding-left: 0;
      color: $black;
      border: 0;
      border-radius: 0;
      background-color: transparent !important;
      font-family: $ff-proxima-nova;
      font-size: 15px;
      font-weight: 300;
      line-height: 26px;

      &::placeholder {
        color: $black;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    .input-border {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      border-right: 1px solid $grey;
      border-bottom: 1px solid $grey;
    }

    textarea {
      &.form-control {
        min-height: 150px;
        max-height: 350px;
        padding: 8px 12px;
        background-color: $grey-light !important;
      }
    }

    input[type="file"] {
      z-index: -1;
      position: absolute;
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
    }

    .file-button {
      @include quart-transition(400ms);
      display: flex;
      margin-bottom: 0;
      padding: 7px 16px;
      color: $white;
      border: 0;
      border-radius: 0;
      background-color: $red;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      justify-content: center;
      cursor: pointer;

      img {
        @include quart-transition(400ms);
        width: 10px;
        margin-left: 7px;
      }

      &:hover {
        color: $white;
        background-color: darken($red, 6%);

        img {
          margin-left: 14px;
        }
      }
    }
  }

  .row {
    align-items: stretch;

    .col-lg-4 {
      display: flex;
      flex-direction: column;
    }
  }
}

/**
 * |==========================================================================|
 * | BACK TO TOP
 * |==========================================================================|
 */

.back-to-top {
  display: flex;
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 40px;
  height: 40px;
  color: $white;
  background-color: $red;
  align-items: center;
  font-size: 21px;
  justify-content: center;

  &:hover {
    color: $white;
    background-color: darken($red, 5%);
    text-decoration: none;
  }
}
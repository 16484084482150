// Extra small devices (portrait phones, less than 576px)

@media
  (max-width : 575.98px) {
  .off-canvas-nav {
    right : -100vw;
    width : 100vw;
  }

  nav {
    .header-menu {
      width     : calc(100vw - 30px);
      max-width : auto;
      padding   : 33px;
    }
  }

  .header-block {
    .content {
      h2 {
        text-align     : right;
        font-size      : 28px;
        line-height    : 38px;
        letter-spacing : 0.1em;
      }

      .cms {
        max-width    : 100% !important;
        margin-right : .5rem;
        margin-left  : .5rem;
      }
    }
  }

  .projects-slider {
    .slider-controls {
      display : none;
      right   : 20px;
      left    : 20px;
    }
  }

  .project-modal {
    .modal-dialog {
      .modal-content {
        .image {
          height : 150px !important;
        }

        .content {
          padding : 25px !important;
        }
      }
    }
  }

  .sub-footer p {
    line-height : 12px;
  }
}

// Small devices (landscape phones, less than 768px)

@media
  (max-width : 767.98px) {
  .overlap-boxes {
    flex-direction : column;

    .left {
      margin-top    : 0;
      margin-bottom : 0;
    }

    .right {
      height      : 300px;
      margin-top  : 60px;
      margin-left : 0 !important;
    }
  }
}

// Medium devices (tablets, less than 992px)

@media
  (max-width : 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right : 15px;
    padding-left  : 15px;
  }

  nav {
    .navbar-brand {
      max-width : 200px;
    }
  }

  .header-block {
    height  : auto;
    padding : 100px 40px;

    .content {
      display         : flex;
      align-items     : flex-end;
      flex-direction  : column;
      justify-content : flex-end;

      .cms {
        max-width : 75%;
      }
    }
  }

  .right-side-slider {
    position : relative;
    right    : 0;
    left     : 0;
    height   : 300px;
  }

  .projects-slider {
    height : 400px;

    .slide-wrapper,
    .slide,
    .slides {
      height : 400px;
    }
  }

  .project-modal {
    .modal-dialog {
      .modal-content {
        flex-direction : column;

        .image {
          height : 300px;
        }

        .content {
          padding : 50px;

          .cms {
            max-width : 100%;
          }
        }
      }
    }
  }

  .overlap-boxes {
    .left {
      flex : 0 0 50%;
    }

    .right {
      margin-left : -25%;
      flex        : 0 0 75%;
    }
  }

  .sub-footer {
    align-items    : flex-start;
    flex-direction : column;
  }
}

// Large devices (desktops, less than 1200px)

@media
  (max-width : 1199.98px) {
}

// ==========[ GENERAL ]==========

* {
  font-feature-settings: "kern"1;
  font-family: $ff-proxima-nova;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  color: $black;
  font-family: $ff-niveau-grotesk;
  font-size: 30px;
  font-weight: 500;
  line-height: 28px;

  span {
    color: $red;
    font-weight: bold;
  }
}

h2 {}

h3 {}

h4 {
  color: $black;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;

  span {
    color: $red;
    font-weight: bold;
  }
}

h5 {
  color: $black;
  font-family: $ff-niveau-grotesk;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

.cms,
p {
  color: $black;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;

  a {
    color: $black;
    text-decoration: underline;

    &:hover {
      color: $red;
      text-decoration: none;
    }

    &.link--nostyle {
      padding: 2px 0;
      color: $white;
      font-family: $ff-niveau-grotesk;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: .05em;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        font-weight: 700;
      }
    }
  }

  b,
  strong {
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
  }
}

ul.plus-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    &::before {
      content: "+";
      margin-right: 6px;
      color: $red;
      font-weight: 600;
    }
  }
}

// ==========[ HEADER ]==========

nav {
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: flex-end;
  justify-content: space-between;

  .navbar-brand {
    max-width: 300px;

    img {
      width: 100%;
    }
  }

  .right {
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .header-social {
    display: flex;
    margin-right: 35px;
    align-items: center;
    justify-content: flex-start;

    p {
      margin-left: 7px;
      color: $black;
      text-align: left;
      font-size: 13px;
      font-weight: 300;
      line-height: 15.6px;
    }

    a {
      &.link--nostyle {
        margin-left: 7px;
        color: $black;
        text-align: left;
        font-size: 13px;
        font-weight: 300;
        line-height: 15.6px;
  
        &:hover {
          text-decoration: none;
          font-weight: 500;
        }
      }
    }
  }

  .header-menu-button {
    @include quart-transition(400ms);
    z-index: 999;
    display: flex;
    width: 35px;
    height: 35px;
    color: $white;
    background-color: $red;
    align-items: center;
    justify-content: center;

    .fa-times {
      display: none;
    }

    &:hover {
      background-color: darken($red, 8%);
    }

    &.active {
      .fa-times {
        display: block;
      }

      .fa-bars {
        display: none;
      }
    }
  }

  .header-menu {
    @include quart-transition(400ms);
    z-index: 998;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 400px;
    padding: 50px 30px 30px 30px;
    background-color: $red;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
    transform: scale(0);
    transform-origin: right top;

    .top {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-start;

      a {
        padding: 2px 0;
        color: $white;
        font-family: $ff-niveau-grotesk;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: .05em;

        &:hover {
          text-decoration: none;
          font-weight: 700;
        }
      }
    }

    .bottom {
      margin-top: 25px;

      .cms {
        color: $white;
        text-align: right;
        font-family: $ff-niveau-grotesk;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: .05em;
      }
    }

    &.active {
      transform: scale(1);
    }
  }
}

// ==========[ FOOTER ]==========

footer {
  padding: 50px;
  background-color: $black;

  .cms,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }

  .footer-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    img {
      margin-right: 25px;
    }
  }

  a {
    color: $white;
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .cms a {
    color: $white;
  }
}

.sub-footer {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;

  p {
    color: $black;
    font-size: 11px;
    font-weight: 300;
    line-height: 20px;

    a {
      color: $black;
      font-weight: bold;

      &:hover {
        color: $red;
        text-decoration: none;
      }
    }
  }

  div p {
    font-size: 9px;
  }
}

// ==========[ HOME ]==========

.right-side-slider {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 15px;
  margin-bottom: 0 !important;

  .slide-wrapper,
  .slide {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .slick-list,
  .slick-slide,
  .slick-track {
    height: 100%;
  }

  .slick-slide div {
    height: 100%;
  }

  .slick-dots {
    display: flex;
    right: 8px;
    bottom: 10px;
    width: auto;

    li {
      display: flex;
      width: 6px;
      height: 6px;
      align-items: center;
      justify-content: center;

      button {
        width: 100%;
        height: 100%;
        color: $grey-light;
        border-radius: 999px;
        background: $grey-light;
      }

      &.slick-active {
        button {
          color: $red;
          background: $red;
        }
      }
    }
  }
}

.projects-slider {
  position: relative;
  width: 100%;
  height: 700px;

  .slick-slide {
    margin-left: 6px;
  }

  .slick-list {
    margin-left: -6px;
  }

  .slider-controls {
    display: flex;
    position: absolute;
    top: calc(50% - 21px);
    right: -21px;
    left: -21px;
    align-items: center;
    justify-content: space-between;

    a {
      @include quart-transition(400ms);
      z-index: 999;
      display: flex;
      width: 42px;
      height: 42px;
      border-radius: 999px;
      background-color: $red;
      align-items: center;
      justify-content: center;

      &.prev-slide {
        transform: rotate(180deg);
      }

      &:hover {
        background-color: darken($red, 6%);
        transform: scale(1.15);

        &.prev-slide {
          transform: scale(1.15) rotate(180deg);
        }
      }
    }
  }

  .slide-wrapper,
  .slide,
  .slides {
    height: 700px;
  }
}

.project-modal {
  .modal-dialog {
    width: 100%;
    max-width: 100%;

    .modal-content {
      @include softshadow;
      border: 0;
      border-radius: 0;

      .image {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        align-items: flex-start;
        justify-content: flex-start;

        .back-button {
          display: flex;
          width: 40px;
          height: 40px;
          color: $white;
          border-radius: 999px;
          background-color: $red;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            transform: rotate(180deg);
          }
        }
      }

      .content {
        padding: 40px 120px 40px 50px;
        background-color: $black;

        .cms,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          color: $white;

          a {
            color: $red;
            text-decoration: underline;

            &:hover {
              color: darken($red, 5%);
              text-decoration: none;
            }
          }
        }

        h6 {
          font-size: 15px;
          font-weight: bold;
          line-height: 21px;
        }

        .tags {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .tag {
            padding-top: 0;
            padding-right: 6px;
            padding-bottom: 0;
            padding-left: 6px;
            color: $white;
            border-right: 2px solid $white;
            font-size: 15px;
            font-weight: bold;
            line-height: 15px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
              border-right: 0;
            }
          }
        }

        .project-image {
          display: block;
          width: 100%;
          padding-top: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
}

.fancybox-container {
  z-index: 1000000 !important;
}

#solliciteer-form {
  display: none;
}

.c-project {
  cursor: pointer;
  text-decoration: none !important;

  span {
    color: $red;
    font-weight: bold;
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
  }

  &__overlay {
    position: relative;
    overflow: hidden;
    height: 12rem;

    &::after {
      transition: opacity 200ms ease-in-out;
      opacity: 0;
      content: '';
      position: absolute;
      background-color: $red;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  &__title {
    color: #161615;
    font-family: 'niveau-grotesk', sans-serif;
    line-height: 1;
    font-weight: 500;
  }

  &:hover & {
    &__overlay {
      &::after {
        opacity: .5;
      }
    }
  }
}

.c-alert {
  color: $white;
  font-family: 'niveau-grotesk',
    sans-serif;
  line-height: 1;
  font-weight: 300;
  border: none;
  border-radius: 0;

  &__strong {
    font-weight: 600;
  }

  &__fail {
    background-color: $red;
  }

  &__success {
    background-color: #78D63C;
  }

  & .close {
    opacity: 1;
    outline: none;
    color: $white;
    padding: .5rem 1.25rem;
  }
}
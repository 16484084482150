// ==========[ FONTS ]==========

@font-face {
  font-family: 'Panton';
  src: url(../fonts/Panton.woff);
  src: url(../fonts/Panton.woff2);
}

$ff-panton         : 'Panton', sans-serif;
$ff-acumin         : 'acumin-pro-extra-condensed', sans-serif;
$ff-niveau-grotesk : 'niveau-grotesk', sans-serif;
$ff-proxima-nova   : 'proxima-nova', sans-serif;


// ==========[ COLORS ]==========

$white         : #FFFFFF;
$grey-light    : #E8E8E8;
$grey          : #BABABA;
$grey-dark     : #5D5C5C;
$black         : #161615;

$red : #E11725;

// ==========[ MIXINS ]==========

@mixin boxshad() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@mixin softshadow() {
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,.3);
}

@mixin quart-transition($duration) {
  transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
}
